import * as React from "react"
import LogoPNG from "../images/logo.png"
import styled from "styled-components"

import "../styles/global.css"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Logo = styled.img`
  width: 90%;
  max-width: 512px;
`

const BlankPage = () => (
  <Container>
    <Logo src={LogoPNG} alt="logo" />
  </Container>
)

export default BlankPage
